import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import "./assets/css/tailwind.css";

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
});

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import Toasted from "vue-toasted";
Vue.use(Toasted);

import vuescroll from "vuescroll";
Vue.use(vuescroll);

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faFaucet,
  faThermometerHalf,
  faVolumeMute,
  faHandHoldingUsd,
  faClock,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faHome,
  faFaucet,
  faThermometerHalf,
  faVolumeMute,
  faHandHoldingUsd,
  faClock,
  faEdit
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = true;

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount("#app");
