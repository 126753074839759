<template>
  <header
    class="sm:flex bg-white sm:justify-between sm:items-center px-4 py-3 fixed w-full z-10 top-0"
  >
    <div class="flex items-center">
      <router-link :to="{ name: 'Home' }">
        <img class="h-12" src="@/assets/img/suviba-logo.svg" alt />
      </router-link>
      <div class="ml-auto mr-5 sm:hidden">
        <button
          @click="isOpen = !isOpen"
          type="button"
          class="text-blue-800 focus:text-blue-500 focus:outline-none hover:text-blue-600"
        >
          <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path
              v-if="isOpen"
              fill-rule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
            />
            <path
              v-if="!isOpen"
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div
      :class="isOpen ? 'block' : 'hidden'"
      class="pt-3 pb-4 sm:flex sm:p-0 uppercase"
      @click="isOpen = false"
    >
      <router-link :to="{ name: 'Home' }">
        <a
          href="#"
          class="block text-blue-700 font-semibold hover:text-blue-600 mt-1 sm:mt-0 sm:ml-2 border-b-2 border-opacity-0 hover:opacity-75 hover:border-red-500"
          >Inicio</a
        >
      </router-link>
      <router-link :to="{ name: 'Casas' }">
        <a
          href="#"
          class="block text-blue-700 font-semibold hover:text-blue-600 mt-1 sm:mt-0 sm:ml-2 border-b-2 border-opacity-0 hover:opacity-75 hover:border-red-500"
          >Casas</a
        >
      </router-link>
      <router-link :to="{ name: 'Galpones' }">
        <a
          href="#"
          class="block text-blue-700 font-semibold hover:text-blue-600 mt-1 sm:mt-0 sm:ml-2 border-b-2 border-opacity-0 hover:opacity-75 hover:border-red-500"
          >Galpones</a
        >
      </router-link>
      <router-link :to="{ name: 'Contacto' }">
        <a
          href="#"
          class="block text-blue-700 font-semibold hover:text-blue-600 mt-1 sm:mt-0 sm:ml-2 border-b-2 border-opacity-0 hover:opacity-75 hover:border-red-500"
          >Contacto</a
        >
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    };
  }
};
</script>

<style>
header {
  box-shadow: #0031ff21 0 -5px 16px 0px;
  animation-name: box-header;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes box-header {
  from {
    box-shadow: #e53e3e52 0 -5px 16px 0px;
  }
  to {
    box-shadow: #0031ff21 0 -5px 16px 0px;
  }
}
.active {
}
</style>
