<template>
  <div class="mt-16">
    <div class="flex justify-center bg-gray-200">
      <img
        src="@/assets/img/full/Casa/construccion/img (3).jpg"
        alt="Suviba S.A."
      />
      <!-- <img class="py-16 animate-logo" src="@/assets/img/suviba-logo.svg" alt="Suviba S.A." /> -->
    </div>

    <div class="p-16 bg-gray-100">
      <p>
        Somos una empresa familiar, cuatro generaciones dedicadas a la
        concepción, diseño, producción y comercialización de viviendas, locales,
        depósitos y galpones.
      </p>
      <p>
        La fábrica, administración y ventas se encuentran ubicadas en la
        localidad de San Carlos Sud, provincia de Santa Fe.
      </p>
      <p>Trabajamos en San Carlos y localidades cercanas.</p>
    </div>

    <div class="p-16 bg-gray-200 flex flex-col">
      <div class>
        <h1 class="font-bold text-3xl text-blue-700">Objetivos</h1>
      </div>
      <div class="sm:flex">
        <div class="flex-1 p-4 border-b-2 sm:border-b-0">
          <img
            src="@/assets/img/svgs/undraw_under_construction_46pa.svg"
            alt
            class="h-40 w-40 mx-auto"
          />
          <p class="text-center">
            Satisfacer las necesidades de nuestros clientes a través de
            diferentes sistemas constructivos, elaborados con la más alta
            tecnología en hormigón armado, garantizando calidad, servicio y
            precios competitivos.
          </p>
        </div>
        <div class="flex-1 p-4 border-b-2 sm:border-b-0">
          <img
            src="@/assets/img/svgs/undraw_select_house_qbag.svg"
            alt
            class="h-40 w-40 mx-auto"
          />
          <p class="text-center">
            Cumplir el sueño de la vivienda propia, su proyecto inmobiliario,
            industrial, comercial y agrícola-ganadero, ofreciendo productos con
            aval de 50 años de experiencia en sistemas premoldeados de hormigón
            armado.
          </p>
        </div>
        <div class="flex-1 p-4">
          <img
            src="@/assets/img/svgs/undraw_at_home_octe.svg"
            alt
            class="h-40 w-40 mx-auto"
          />
          <p class="text-center">
            Continuar siendo una empresa constructora líder en la región,
            referente en el rubro de Sistemas Constructivos Industrializados.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {}
};
</script>

<style></style>
