import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/casas",
    name: "Casas",
    component: () => import("../views/Casas.vue")
  },
  {
    path: "/galpones",
    name: "Galpones",
    component: () => import("../views/Galpones.vue")
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: () => import("../views/Contacto.vue")
  }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
