<template id="app">
  <vue-scroll>
    <div class="bg-gray-200 w-full h-full min-h-screen flex flex-col">
      <Navbar></Navbar>

      <router-view class="flex-1" />
      <footer class="bg-gray-500 flex justify-center mt-auto">
        <p class="text-gray-100">Suviba S.A. © 2020</p>
        <small
          class="text-gray-100 absolute right-0 mx-5 font-light invisible md:visible"
        >
          <a href="mailto:dgastonalain@gmail.com">Web: Gaston D.</a>
        </small>
      </footer>
    </div>
  </vue-scroll>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  components: {
    Navbar
  }
};
</script>

<style></style>
